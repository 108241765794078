var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tooltip', {
    attrs: {
      "text": _vm.readText
    }
  }, [_vm.isReadByMe ? _c('v-icon', {
    attrs: {
      "small": _vm.small
    }
  }, [_vm._v(" mdi-check-all ")]) : _vm.isReadByAnyone ? _c('v-icon', {
    attrs: {
      "small": _vm.small
    }
  }, [_vm._v(" mdi-check ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }