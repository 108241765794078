var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-main', {
    staticClass: "pa-0",
    staticStyle: {
      "width": "100%"
    }
  }, [_vm._l(_vm.value, function (_, index) {
    return _c('v-card', {
      key: "".concat(index, ".").concat(Math.random()),
      staticClass: "pa-0",
      style: _vm.invertedBackgroundColorLight,
      attrs: {
        "dto": _vm.form,
        "flat": ""
      }
    }, [_c('v-card', {
      staticClass: "pa-0 mb-4 ml-1 mt-n1 mr-n1",
      staticStyle: {
        "width": "calc(100% - 2px)"
      },
      attrs: {
        "flat": ""
      }
    }, [_vm.countCategories > 1 ? _c('v-card-subtitle', {
      staticClass: "d-flex "
    }, [_c('b', [_vm._v(" #" + _vm._s(index + 1) + " ")]), _c('v-spacer'), _c('v-btn', {
      attrs: {
        "x-small": "",
        "text": ""
      },
      on: {
        "click": function click($event) {
          return _vm.removeIndex(index);
        }
      }
    }, [_vm._v(_vm._s(_vm.$t("remove")))])], 1) : _vm._e(), _vm.configs[index] ? _c('m-detail-form', {
      attrs: {
        "config": _vm.configs[index],
        "item": _vm.value[index],
        "partnerId": _vm.partnerId,
        "abortChanges": _vm.abortChanges,
        "syncChanges": _vm.syncChanges(index),
        "containButtons": true,
        "hideSearch": true,
        "outsideSearch": _vm.search,
        "smallTitles": true,
        "autoSync": true
      },
      scopedSlots: _vm._u([_vm.countCategories <= 1 ? {
        key: "expansionHeader",
        fn: function fn() {
          return [_c('div', {
            staticClass: "d-flex"
          }, [_c('b', [_vm._v(" #" + _vm._s(index + 1) + " ")]), _c('v-spacer'), _c('v-btn', {
            attrs: {
              "x-small": "",
              "text": ""
            },
            on: {
              "click": function click($event) {
                return _vm.removeIndex(index);
              }
            }
          }, [_vm._v(_vm._s(_vm.$t("remove")))])], 1)];
        },
        proxy: true
      } : null], null, true)
    }) : _vm._e()], 1)], 1);
  }), _c('v-card', {
    staticClass: "pa-0 mb-4",
    attrs: {
      "outlined": "",
      "flat": ""
    }
  }, [_c('v-list-item', {
    staticStyle: {
      "height": "56px"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.addNewValue.apply(null, arguments);
      }
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-plus")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.$t("add")) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.form.formables.length) + " " + _vm._s(_vm.$t("designGuide.MDetailForm.props")) + " ")])], 1)], 1)], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }