var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-list-item', {
    attrs: {
      "three-line": ""
    }
  }, [_c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.item.timestamp.createdReadable) + " " + _vm._s(_vm.mail) + " ")]), _c('v-list-item-title', {
    staticClass: "text-truncate "
  }, [_c('div', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('div', [_vm.hasOpenDetailListener() ? _c('a', {
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.emitOpenDetail.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.subject) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.subject) + " ")])]), _c('v-spacer')], 1)]), !_vm.small ? _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.body))]) : _vm._e()], 1), _c('v-list-item-action', [_c('span', {
    staticStyle: {
      "display": "flex",
      "align": "center"
    }
  }, [_vm.item.attachments && _vm.item.attachments.length ? _c('tooltip', {
    attrs: {
      "text": _vm.item.attachments.map(function (a) {
        return "".concat(a.name, " (").concat(a.sizeReadable, ")");
      }).join('<br/><br/>')
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" mdi-attachment ")])], 1) : _vm._e(), _c('viewed-preview', {
    key: 'viewed-preview' + _vm.item.viewed.length,
    attrs: {
      "small": true,
      "value": _vm.item.viewed
    }
  })], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }