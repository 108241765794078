var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Can', {
    attrs: {
      "I": _vm.ActionEnum.CREATE,
      "a": _vm.ResourceEnum.TICKET,
      "field": _vm.partnerId
    }
  }, [!_vm.hideButton && !_vm.isIconButton ? _c('v-btn', {
    attrs: {
      "color": "info",
      "elevation": "0",
      "loading": _vm.isLoadingStartDialog,
      "small": _vm.smallBtn
    },
    domProps: {
      "textContent": _vm._s(_vm.$t('project.ticket.newTicket'))
    },
    on: {
      "click": _vm.startDialog
    }
  }) : _vm._e(), _vm._t("activator", function () {
    return [!_vm.hideButton && _vm.isIconButton ? _c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": _vm.startDialog
      }
    }, [_c('v-icon', [_vm._v(" mdi-ticket-outline")])], 1) : _vm._e()];
  }, {
    "slotScope": {
      open: function open() {
        return _vm.dialogActive = true;
      }
    }
  })], 2), _vm.dialogActive ? _c('confirm-action-dialog', {
    key: 'confirm-action-dialog' + _vm.dialogActive,
    attrs: {
      "width": "1050",
      "isDialogActive": _vm.dialogActive,
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown,
      "loading": _vm.isLoading,
      "title": _vm.$t('project.ticket.stepper.title.createTicket'),
      "supressKeyboardActions": true,
      "leftLoading": _vm.isLoading,
      "rightLoading": _vm.isLoading,
      "rightDisabled": !_vm.ticket.title,
      "leftText": _vm.$t('close'),
      "rightText": _vm.$t('create')
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.dialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.dialogActive = $event;
      },
      "leftClick": _vm.close,
      "rightClick": _vm.onCreateTicket,
      "close": _vm.close
    }
  }, [_c('ticket-create-card', {
    key: 'ticket-create-card',
    attrs: {
      "ticket": _vm.ticket,
      "partnerId": _vm.partnerId
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }