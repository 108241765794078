var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    ref: "imageContainer",
    staticClass: "ma-0",
    style: _vm.backgroundColor + "border: 1px solid lightgrey;",
    attrs: {
      "id": "imageContainer"
    }
  }, [_vm.isSpacer ? _c('v-spacer') : _vm._e(), _c('v-img', {
    key: _vm.src,
    ref: _vm.markerImage,
    class: _vm.imageClass,
    staticStyle: {
      "position": "relative"
    },
    attrs: {
      "id": _vm.markerImage,
      "src": _vm.src,
      "max-height": _vm.maxHeight,
      "max-width": _vm.maxWidth
    },
    on: {
      "loadstart": _vm.onImageLoadstart,
      "load": _vm.onImageLoad,
      "mousedown": _vm.onMouseDown,
      "mouseup": _vm.onMouseUp,
      "mousemove": _vm.onMouseMove,
      "touchstart": _vm.onTouchStart,
      "touchend": _vm.onTouchEnd,
      "touchmove": _vm.onTouchMove
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-center justify-center fill-height",
          staticStyle: {
            "min-height": "30px"
          }
        }, [_c('v-progress-circular', {
          attrs: {
            "color": "grey-lighten-4",
            "indeterminate": ""
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_vm.showMagnifier && !_vm.deactivated ? _c('div', {
    staticClass: "magnifier",
    style: _vm.magnifierStyle
  }, [_c('div', {
    staticClass: "magnifier-target"
  })]) : _vm._e(), _vm.imageLoaded ? _c('div', {
    staticStyle: {
      "position": "relative"
    }
  }, _vm._l(_vm.markersStyled, function (marker, idx) {
    return _c('span', {
      key: _vm.src + idx + marker.color,
      class: _vm.markerClass,
      style: marker.style,
      on: {
        "hover": function hover($event) {
          return _vm.markerHover(marker);
        },
        "mouseover": function mouseover($event) {
          return _vm.$emit('mouseoverMarker', marker);
        },
        "mouseleave": function mouseleave($event) {
          return _vm.$emit('mouseleaveMarker', marker);
        },
        "click": function click($event) {
          return _vm.markerClicked(marker);
        }
      }
    }, [_vm.showText && marker.text ? [_vm._v(_vm._s(marker.text))] : _vm._e()], 2);
  }), 0) : _vm._e()]), _c('debug', [_vm._v(" max-height: " + _vm._s(_vm.maxHeight) + " "), _c('br'), _vm._v(" max-width: " + _vm._s(_vm.maxWidth) + " ")]), _vm.isSpacer ? _c('v-spacer') : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }