var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    class: {
      'text-decoration-line-through': _vm.item.isArchived
    }
  }, [_vm._v(" " + _vm._s(_vm.text) + " ")]);
}
var staticRenderFns = []

export { render, staticRenderFns }