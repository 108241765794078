var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('timeline-card', {
    attrs: {
      "title": _vm.$t('timeLine.ActivityTimeLineItemCreateCost.title', {
        name: _vm.name,
        date: _vm.date
      })
    },
    scopedSlots: _vm._u([{
      key: "menu",
      fn: function fn() {
        return [_c('v-btn', {
          staticClass: "mt-n1",
          attrs: {
            "x-small": "",
            "outlined": "",
            "color": "info",
            "disabled": !_vm.cost
          },
          on: {
            "click": _vm.openSideCard
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("timeLine.ActivityTimeLineItemTicket.detail")) + " ")]), _c('activity-time-line-item-delete', {
          attrs: {
            "partnerId": _vm.activityLogEntry.partnerId,
            "activityId": _vm.activityLogEntry.id
          }
        })];
      },
      proxy: true
    }])
  }, [_vm.isLoading ? _c('v-skeleton-loader', {
    attrs: {
      "type": "article"
    }
  }) : _vm.cost ? _c('refs-cost', {
    ref: "refCard",
    attrs: {
      "item": _vm.cost
    },
    on: {
      "openDetail": _vm.openSideCard
    }
  }) : !_vm.cost ? _c('latest-entries-card-empty') : _vm._e(), _vm.showSideCard && _vm.cost ? _c('cost-side-card', {
    attrs: {
      "value": _vm.cost
    },
    on: {
      "close": function close($event) {
        _vm.showSideCard = false;
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }