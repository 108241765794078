var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('card', {
    attrs: {
      "margin": 0,
      "flat": "",
      "outlined": "",
      "hideTitle": true,
      "subtitle": _vm.$t('components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.registration.accidentDetails.title')
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [!_vm.showRemove ? _c('v-btn', {
          attrs: {
            "text": "",
            "data-test-accident-add-btn": ""
          },
          on: {
            "click": _vm.add
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("add")) + " ")]) : _vm._e(), _vm.showRemove ? _c('v-btn', {
          attrs: {
            "text": "",
            "data-test-accident-add-btn": ""
          },
          on: {
            "click": _vm.remove
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("close")) + " ")]) : _vm._e()];
      },
      proxy: true
    }])
  }, [_vm.showRemove ? _c('div', {
    staticClass: "pt-2 pr-2 pl-2"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.reportDto.accidentDetails ? _c('v-select', {
    attrs: {
      "label": _vm.$t('components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.accidentDetails.role'),
      "flat": "",
      "outlined": "",
      "items": _vm.roleItems
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(_vm.$t("report.witness.roles.".concat(item))) + " ")];
      }
    }, {
      key: "selection",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm.$t("report.witness.roles.".concat(item))) + " ")];
      }
    }], null, false, 911662620),
    model: {
      value: _vm.reportDto.accidentDetails.role,
      callback: function callback($$v) {
        _vm.$set(_vm.reportDto.accidentDetails, "role", $$v);
      },
      expression: "reportDto.accidentDetails.role"
    }
  }) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-subheader', [_vm._v(" " + _vm._s(_vm.$t("report.accidentDetails.date")) + " ")]), _vm.reportDto.accidentDetails && _vm.reportDto.accidentDetails.date ? _c('date-time-picker', {
    attrs: {
      "date": _vm.accidentDetailsDate
    },
    on: {
      "update:date": function updateDate($event) {
        _vm.accidentDetailsDate = $event;
      }
    }
  }) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-subheader', [_vm._v(" " + _vm._s(_vm.$t("report.accidentDetails.location.isAddress")) + " ")]), _c('v-text-field', {
    attrs: {
      "label": _vm.$t('components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.accidentDetails.street'),
      "flat": "",
      "outlined": ""
    },
    model: {
      value: _vm.reportDto.accidentDetails.address.street,
      callback: function callback($$v) {
        _vm.$set(_vm.reportDto.accidentDetails.address, "street", $$v);
      },
      expression: "reportDto.accidentDetails.address.street"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.$t('components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.accidentDetails.zip'),
      "flat": "",
      "outlined": ""
    },
    model: {
      value: _vm.reportDto.accidentDetails.address.zip,
      callback: function callback($$v) {
        _vm.$set(_vm.reportDto.accidentDetails.address, "zip", $$v);
      },
      expression: "reportDto.accidentDetails.address.zip"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.$t('components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.accidentDetails.city'),
      "flat": "",
      "outlined": ""
    },
    model: {
      value: _vm.reportDto.accidentDetails.address.city,
      callback: function callback($$v) {
        _vm.$set(_vm.reportDto.accidentDetails.address, "city", $$v);
      },
      expression: "reportDto.accidentDetails.address.city"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.$t('components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.accidentDetails.state'),
      "flat": "",
      "outlined": ""
    },
    model: {
      value: _vm.reportDto.accidentDetails.address.state,
      callback: function callback($$v) {
        _vm.$set(_vm.reportDto.accidentDetails.address, "state", $$v);
      },
      expression: "reportDto.accidentDetails.address.state"
    }
  }), _c('v-select', {
    attrs: {
      "label": _vm.$t('components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.accidentDetails.countryCode'),
      "flat": "",
      "outlined": "",
      "items": _vm.countryCodeItems
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(_vm.$t("CountryCodeEnum.".concat(item))) + " ")];
      }
    }, {
      key: "selection",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(_vm.$t("CountryCodeEnum.".concat(item))) + " ")];
      }
    }], null, false, 768724316),
    model: {
      value: _vm.reportDto.accidentDetails.address.countryCode,
      callback: function callback($$v) {
        _vm.$set(_vm.reportDto.accidentDetails.address, "countryCode", $$v);
      },
      expression: "reportDto.accidentDetails.address.countryCode"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.$t('components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.accidentDetails.lat'),
      "flat": "",
      "outlined": ""
    },
    model: {
      value: _vm.accidentGeoLat,
      callback: function callback($$v) {
        _vm.accidentGeoLat = $$v;
      },
      expression: "accidentGeoLat"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.$t('components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.accidentDetails.lng'),
      "flat": "",
      "outlined": ""
    },
    model: {
      value: _vm.accidentGeoLng,
      callback: function callback($$v) {
        _vm.accidentGeoLng = $$v;
      },
      expression: "accidentGeoLng"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-subheader', [_vm._v(_vm._s(_vm.$t("report.accidentDetails.location.comment.label")))]), _c('v-textarea', {
    attrs: {
      "label": _vm.$t('components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.accidentDetails.comment'),
      "flat": "",
      "outlined": ""
    },
    model: {
      value: _vm.reportDto.accidentDetails.comment,
      callback: function callback($$v) {
        _vm.$set(_vm.reportDto.accidentDetails, "comment", $$v);
      },
      expression: "reportDto.accidentDetails.comment"
    }
  })], 1)], 1)], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }