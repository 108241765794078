var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.item ? _c('v-list-item', {
    staticClass: "pa-0",
    on: {
      "click": _vm.copy
    }
  }, [_vm.item ? _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.item.street) + " "), !_vm.small && _vm.item.isBilling ? _c('v-chip', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("objects.company.addresses.isBilling")) + " ")]) : _vm._e()], 1), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.item.zip) + " " + _vm._s(_vm.item.city) + " " + _vm._s(_vm._f("getFlagEmoji")(_vm.item.countryCode)))])], 1) : _vm._e()], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }