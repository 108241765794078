import { render, staticRenderFns } from "./CopyClipboardText.vue?vue&type=template&id=2c1ec553&scoped=true&"
import script from "./CopyClipboardText.vue?vue&type=script&lang=ts&"
export * from "./CopyClipboardText.vue?vue&type=script&lang=ts&"
import style0 from "./CopyClipboardText.vue?vue&type=style&index=0&id=2c1ec553&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c1ec553",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
