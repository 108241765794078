var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.item.loading ? _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "text-truncate "
  }, [_c('div', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('div', [_vm.hasOpenDetailListener() ? _c('a', {
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.emitOpenDetail.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.item.name) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.item.name) + " ")])]), _c('v-spacer')], 1)]), _c('v-list-item-subtitle', {
    staticClass: "text-truncate text-xs"
  }, [_vm._v(" " + _vm._s(_vm.item.iban) + " - " + _vm._s(_vm.item.bank) + " ")])], 1)], 1) : _c('v-skeleton-loader', {
    attrs: {
      "type": "list-item-three-line"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }