var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.item.loading ? _c('v-list-item', {
    attrs: {
      "three-line": ""
    }
  }, [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "text-truncate "
  }, [_c('div', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('div', [_vm.hasOpenDetailListener() ? _c('a', {
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.emitOpenDetail.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.item.title) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.item.title) + " ")])]), _c('v-spacer'), !_vm.hideCost ? _c('cost-chip', {
    attrs: {
      "value": _vm.item,
      "small": true
    }
  }) : _vm._e()], 1)]), _vm.item.description ? _c('v-list-item-subtitle', {
    staticClass: "text-truncate text-xs"
  }, [_vm._v(" " + _vm._s(_vm.item.description) + " ")]) : _vm._e(), !_vm.small ? _c('v-list-item-subtitle', {
    staticClass: "text-xs text-truncate"
  }, [!_vm.hideDate ? [_c('v-chip', {
    staticClass: "mr-1",
    attrs: {
      "x-small": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.date) + " ")])] : _vm._e(), _vm.loadingProject ? _c('v-skeleton-loader', {
    attrs: {
      "type": "chip"
    }
  }) : _vm.group ? [_c('v-chip', {
    staticClass: "mr-1",
    attrs: {
      "x-small": ""
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.goToCostGroupCustomView.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("cost.group")) + ": " + _vm._s(_vm.group.title) + " "), _c('v-icon', {
    staticClass: "ml-1",
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("mdi-open-in-new")])], 1)] : _vm._e(), _vm._l(_vm.item.tags, function (tag, index) {
    return [_c('v-chip', {
      key: 'cost-tags' + tag + index,
      staticClass: "mr-1",
      attrs: {
        "x-small": ""
      }
    }, [_vm._v(" " + _vm._s(tag) + " ")])];
  })], 2) : _vm._e()], 1)], 1) : _c('v-skeleton-loader', {
    attrs: {
      "type": "list-item-three-line"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }