var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isDialogActive,
      "absolute": true,
      "leftDisabled": _vm.isLoadingSignSnapshot,
      "rightDisabled": _vm.rightDisabled,
      "leftText": _vm.leftText,
      "rightText": _vm.rightText,
      "rightLoading": _vm.isLoadingCreateSnapshot || _vm.isLoadingSignSnapshot,
      "hideLeft": _vm.isLoadingCreateSnapshot || _vm.isLoadingSignSnapshot,
      "hideRight": _vm.hideRight,
      "title": _vm.title,
      "subtitle": _vm.description,
      "loading": _vm.isLoading,
      "persistent": true,
      "fullscreen": true
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "close": _vm.close,
      "rightClick": _vm.rightClick,
      "leftClick": _vm.leftClick
    }
  }, [_vm.step === 0 && _vm.isLoadingPreview ? _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-actions', {
    style: "height: ".concat(_vm.height)
  }, [_c('v-list-item', {
    staticClass: "ma-auto",
    staticStyle: {
      "max-width": "360px"
    }
  }, [_c('v-list-item-icon', [_c('v-progress-circular', {
    attrs: {
      "indeterminate": ""
    }
  })], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.$t("snapshot.loadingPreview")) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t("snapshot.loadingPreviewSubtitle")) + " ")])], 1)], 1)], 1)], 1) : _vm.step === 0 && !_vm.previewPdf ? _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-actions', {
    style: "height: ".concat(_vm.height)
  }, [_c('v-list-item', {
    staticClass: "ma-auto",
    staticStyle: {
      "max-width": "360px"
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-alert")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.$t("snapshot.somethingWentWrong")) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t("snapshot.somethingWentWrongSubtitle")) + " ")])], 1), _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "text": "",
      "outlined": "",
      "icon": ""
    },
    on: {
      "click": _vm.open
    }
  }, [_c('v-icon', [_vm._v("mdi-refresh")])], 1)], 1)], 1)], 1)], 1) : _vm.step === 0 ? _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('pdf-viewer', {
    attrs: {
      "height": _vm.height,
      "pdf": _vm.previewPdf,
      "vertical": !_vm.isMobilePdfViewer,
      "horizontal": _vm.isMobilePdfViewer
    }
  })], 1) : _vm.step === 1 && _vm.isLoadingCreateSnapshot ? _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-actions', {
    style: "height: ".concat(_vm.height)
  }, [_c('v-list-item', {
    staticClass: "ma-auto",
    staticStyle: {
      "max-width": "360px"
    }
  }, [_c('v-list-item-icon', [_c('v-progress-circular', {
    attrs: {
      "indeterminate": ""
    }
  })], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.$t("snapshot.loadingSnapshot")) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t("snapshot.loadingSnapshotSubtitle")) + " ")])], 1)], 1)], 1)], 1) : _vm.step === 1 && _vm.isLoadingSignSnapshot ? _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-actions', {
    style: "height: ".concat(_vm.height)
  }, [_c('v-list-item', {
    staticClass: "ma-auto",
    staticStyle: {
      "max-width": "360px"
    }
  }, [_c('v-list-item-icon', [_c('v-progress-circular', {
    attrs: {
      "indeterminate": ""
    }
  })], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.$t("snapshot.loadingSignSnapshot")) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t("snapshot.loadingSignSnapshotSubtitle")) + " ")])], 1)], 1)], 1)], 1) : _vm.step === 1 && _vm.snapshotPdf ? _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', {
    class: _vm.isMobilePdfViewer ? 'pa-0' : ''
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": 12,
      "md": 6
    }
  }, [_c('pdf-viewer', {
    attrs: {
      "height": "calc(".concat(_vm.height, " - 50px)"),
      "pdf": _vm.snapshotPdf,
      "vertical": !_vm.isMobilePdfViewer,
      "horizontal": _vm.isMobilePdfViewer
    }
  })], 1), _c('v-col', {
    staticClass: "pl-2",
    attrs: {
      "cols": 12,
      "md": 6
    }
  }, [_c('v-list', _vm._l(_vm.signers, function (signer, index) {
    return _c('v-list-item', {
      key: 'signer' + index + signer.firstName + signer.lastName
    }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(signer.firstName) + " " + _vm._s(signer.lastName))])], 1), _c('v-list-item-action', [_c('v-img', {
      attrs: {
        "src": _vm.signatures[index],
        "max-width": "80",
        "max-height": "40"
      }
    })], 1), _c('v-list-item-action', [_c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function click() {
          return _vm.removeSignature(index);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1)], 1);
  }), 1), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "success",
      "outlined": "",
      "block": ""
    },
    on: {
      "click": function click($event) {
        _vm.isNewSignature = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("snapshot.addAdditionalSignature")) + " "), _c('v-icon', [_vm._v(" mdi-plus ")])], 1)], 1), _c('v-bottom-sheet', {
    attrs: {
      "value": _vm.isNewSignature,
      "inset": _vm.$vuetify.breakpoint.lgAndUp,
      "scrollable": "",
      "persistent": ""
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', [_c('v-row', [_c('v-col', {
    staticStyle: {
      "display": "flex"
    },
    attrs: {
      "cols": 12
    }
  }, [_c('div', {
    staticClass: "my-auto pt-4",
    staticStyle: {
      "display": "flex",
      "align-items": "center"
    }
  }, [_c('v-icon', {
    staticClass: "ma-2",
    attrs: {
      "large": ""
    }
  }, [_vm._v(" mdi-playlist-check ")]), _c('v-list-title', [_vm._v(" " + _vm._s(_vm.$t("snapshot.signatureTerms")) + " ")])], 1)])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": 12,
      "md": 4
    }
  }, [_c('div', {
    staticClass: "mb-n6 mt-6"
  }, [_c('div', {
    staticClass: "mb-4",
    staticStyle: {
      "display": "flex"
    }
  }, [_c('v-spacer'), _vm.value.attendees && _vm.value.attendees.length ? _c('v-menu', {
    attrs: {
      "bottom": "",
      "left": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "small": "",
            "text": ""
          }
        }, 'v-btn', attrs, false), on), [_vm._v(" " + _vm._s(_vm.$t("common.verbs.select")) + " ")])];
      }
    }], null, false, 2326919396)
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-list', {
    staticStyle: {
      "max-height": "200px",
      "overflow-y": "auto"
    }
  }, _vm._l(_vm.value.attendees, function (signer, index) {
    return _c('v-list-item', {
      key: 'value.attendees.signer' + index + signer.firstName + signer.lastName + signer.companyName,
      on: {
        "click": function click() {
          _vm.createSignDto.firstName = signer.firstName;
          _vm.createSignDto.lastName = signer.lastName;
          _vm.createSignDto.company = signer.companyName;
        }
      }
    }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(signer.firstName) + " " + _vm._s(signer.lastName))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(signer.companyName))])], 1)], 1);
  }), 1)], 1)], 1) : _vm._e()], 1), _c('v-form', {
    staticClass: "my-auto",
    staticStyle: {
      "width": "100%"
    },
    model: {
      value: _vm.isFormValid,
      callback: function callback($$v) {
        _vm.isFormValid = $$v;
      },
      expression: "isFormValid"
    }
  }, [_c('v-combobox', {
    attrs: {
      "label": _vm.$t('sign.Sign.firstName'),
      "rules": _vm.requiredRule,
      "items": _vm.suggestedSigners.map(function (v) {
        return v.firstName;
      }),
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.createSignDto.firstName,
      callback: function callback($$v) {
        _vm.$set(_vm.createSignDto, "firstName", $$v);
      },
      expression: "createSignDto.firstName"
    }
  }), _c('v-combobox', {
    attrs: {
      "label": _vm.$t('sign.Sign.lastName'),
      "rules": _vm.requiredRule,
      "items": _vm.suggestedSigners.map(function (v) {
        return v.lastName;
      }),
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.createSignDto.lastName,
      callback: function callback($$v) {
        _vm.$set(_vm.createSignDto, "lastName", $$v);
      },
      expression: "createSignDto.lastName"
    }
  }), _c('v-combobox', {
    attrs: {
      "label": _vm.$t('sign.Sign.company'),
      "items": _vm.suggestedSigners.map(function (v) {
        return v.company;
      }),
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.createSignDto.company,
      callback: function callback($$v) {
        _vm.$set(_vm.createSignDto, "company", $$v);
      },
      expression: "createSignDto.company"
    }
  })], 1)], 1)]), _c('v-col', {
    attrs: {
      "cols": 12,
      "md": 8
    }
  }, [_c('div', {
    staticClass: "mx-auto",
    style: "width:".concat(_vm.width, "px")
  }, [_c('span', {
    staticClass: "pa-1 overlay",
    style: _vm.overlayStyle
  }, [_vm._v(" " + _vm._s(_vm.$t("sign.Sign.signOverlay")))]), _c('vue-signature-pad', {
    key: "i-am-a-frustrating-pad-and-only-work-when-this-frigging-key-switches-".concat(_vm.signPadMotivationalKey),
    ref: "signaturePad",
    staticClass: "mt-2 mx-auto",
    attrs: {
      "id": "signature",
      "width": "".concat(_vm.width, "px"),
      "height": "".concat(_vm.width / 2, "px")
    }
  }), _c('span', {
    staticClass: "signLine unselectable"
  }), _c('div', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('span', {
    staticClass: "pt-1"
  }, [_vm._v(" " + _vm._s(_vm._f("simpleDoubleDigitDate")(new Date())) + " ")]), _c('v-spacer'), _c('v-tooltip', {
    attrs: {
      "left": "",
      "nudge-left": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on;
        return [_c('v-btn', _vm._g({
          attrs: {
            "small": "",
            "icon": ""
          },
          on: {
            "click": _vm.clearSignPad
          }
        }, on), [_c('v-icon', [_vm._v(" mdi-redo-variant ")])], 1)];
      }
    }])
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("sign.Sign.clear")))])])], 1)], 1)])], 1), _c('v-row', [_c('v-col', {
    staticStyle: {
      "display": "flex"
    },
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticStyle: {
      "width": "35%"
    },
    attrs: {
      "elevation": 0,
      "x-large": "",
      "data-test-delete-file": ""
    },
    on: {
      "click": _vm.leftClick
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("back")) + " ")]), _c('v-spacer'), _c('v-btn', {
    staticStyle: {
      "width": "60%"
    },
    attrs: {
      "disabled": _vm.isNewSignature && !_vm.isFormValid,
      "color": "success",
      "elevation": 0,
      "x-large": ""
    },
    on: {
      "click": _vm.rightClick
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("confirm")) + " ")])], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1) : _vm.step === 1 && !_vm.snapshotPdf ? _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("common.snapshot.somethingWentWrong")) + " ")]) : _vm.step === 2 && _vm.isLoadingSignSnapshot ? _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-actions', {
    style: "height: ".concat(_vm.height)
  }, [_c('v-list-item', {
    staticClass: "ma-auto",
    staticStyle: {
      "max-width": "360px"
    }
  }, [_c('v-list-item-icon', [_c('v-progress-circular', {
    attrs: {
      "indeterminate": ""
    }
  })], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.$t("snapshot.loadingSigningSnapshot")) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t("snapshot.loadingSigningSnapshotSubtitle")) + " ")])], 1)], 1)], 1)], 1) : _vm.step === 2 ? _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', [_c('v-form', {
    model: {
      value: _vm.isFormValid,
      callback: function callback($$v) {
        _vm.isFormValid = $$v;
      },
      expression: "isFormValid"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('snapshot.location'),
      "outlined": "",
      "rules": _vm.requiredRule,
      "readonly": _vm.isLoadingSignSnapshot
    },
    model: {
      value: _vm.location,
      callback: function callback($$v) {
        _vm.location = $$v;
      },
      expression: "location"
    }
  })], 1)], 1)], 1) : _vm.step === 3 ? _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('pdf-viewer', {
    attrs: {
      "height": _vm.height,
      "pdf": _vm.signedSnapshotPdf,
      "vertical": !_vm.isMobilePdfViewer,
      "horizontal": _vm.isMobilePdfViewer
    }
  })], 1) : _vm._e(), _c('debug', [_vm._v(_vm._s(_vm.value))])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }