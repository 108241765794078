var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "hoverCurserMouse"
  }, [_c('tooltip', {
    attrs: {
      "text": _vm.$t(_vm.toolTipText)
    }
  }, [_c('span', {
    staticClass: "hoverCurserMouse",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.copy.apply(null, arguments);
      }
    }
  }, [_vm._t("default"), _vm.icon ? _c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.icon))]) : _vm._e(), _c('span', [_vm._v(_vm._s(_vm.text))])], 2)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }