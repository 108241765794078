var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', {
    staticClass: "pb-2",
    attrs: {
      "flat": "",
      "elevation": 0
    }
  }, [_vm.title ? _c('v-card-title', [_vm._v(" " + _vm._s(_vm.title)), _c('v-spacer'), _vm.panels.length ? _c('v-btn', {
    attrs: {
      "text": "",
      "small": "",
      "color": "info"
    },
    on: {
      "click": function click($event) {
        _vm.panels = [];
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("designGuide.MDetailForm.collapse")) + " ")]) : _vm.filteredList.length ? _c('v-btn', {
    attrs: {
      "text": "",
      "small": "",
      "color": "info"
    },
    on: {
      "click": _vm.openAllPanels
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("designGuide.MDetailForm.expand")) + " ")]) : _vm._e()], 1) : _vm._e(), _vm.description ? _c('v-card-text', [_vm._v(_vm._s(_vm.description))]) : _vm._e(), _c('div', {
    staticClass: "mx-4"
  }, [_vm._t("description")], 2), !_vm.hideSearch ? _c('v-text-field', {
    staticClass: "mx-4",
    attrs: {
      "loading": _vm.isLoading || _vm.loading,
      "outlined": "",
      "dense": "",
      "placeholder": _vm.$t('designGuide.MDetailForm.search'),
      "prepend-inner-icon": "mdi-magnify"
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }) : _vm._e(), _c('v-expansion-panels', {
    class: _vm.autoSync ? 'mb-n3' : 'mb-10',
    attrs: {
      "multiple": "",
      "flat": ""
    },
    model: {
      value: _vm.panels,
      callback: function callback($$v) {
        _vm.panels = $$v;
      },
      expression: "panels"
    }
  }, [_vm._l(_vm.clusters, function (cluster, index) {
    return _c('v-expansion-panel', {
      key: index
    }, [_c('v-expansion-panel-header', {
      staticClass: "custom-expansion-panel",
      scopedSlots: _vm._u([{
        key: "actions",
        fn: function fn() {
          return [_c('div', {
            staticClass: "d-flex align-center"
          }, [_vm.isClearableCategory(cluster) ? _c('v-btn', {
            attrs: {
              "x-small": "",
              "text": ""
            },
            on: {
              "click": function click($event) {
                $event.stopPropagation();
                return _vm.clearCategory(cluster);
              }
            }
          }, [_vm._v(" " + _vm._s(_vm.$t("remove")) + " ")]) : _vm._e(), _c('v-icon', {
            staticClass: "mr-4",
            attrs: {
              "x-large": "",
              "color": "info"
            }
          }, [_vm._v(" mdi-chevron-down ")])], 1)];
        },
        proxy: true
      }], null, true)
    }, [_c('span', {
      staticClass: "ml-4",
      class: _vm.smallTitles ? '' : 'title'
    }, [_vm._t("expansionHeader", function () {
      return [!_vm.smallTitles ? _c('span', [_vm._v(" " + _vm._s(_vm.$t(cluster)) + " ")]) : _c('b', [_vm._v(" " + _vm._s(_vm.$t(cluster)) + " ")]), !_vm.getObjectsForCategory(cluster).some(function (c) {
        return c.type === _vm.DetailFormComponentsEnum.ARRAY;
      }) ? _c('span', {
        staticClass: "text-caption"
      }, [_vm.search ? _c('span', [_vm._v(" " + _vm._s(_vm.getObjectsForCategory(cluster).length) + " " + _vm._s(_vm.$t("designGuide.MDetailForm.from")) + " ")]) : _vm._e(), _vm._v(" " + _vm._s(_vm.countObjectsForCategory(cluster)) + " " + _vm._s(_vm.$t("designGuide.MDetailForm.props")) + " ")]) : _vm._e(), _c('br'), _vm.getClusterHint(cluster) ? _c('small', {
        staticClass: "text-caption",
        staticStyle: {
          "position": "relative",
          "top": "-8px"
        }
      }, [_vm._v(" " + _vm._s(_vm.$t(_vm.getClusterHint(cluster))) + " ")]) : _vm._e()];
    })], 2)]), _c('div', {
      staticClass: "ml-n2 mr-n2"
    }, [_c('v-expansion-panel-content', {
      staticClass: "pr-0 pl-0 mb-n4"
    }, _vm._l(_vm.getObjectsForCategory(cluster), function (inputFieldConfig) {
      return _c('div', {
        key: _vm.removeCategoryUpdateKey + inputFieldConfig.key + inputFieldConfig.condition
      }, [_c('v-hover', {
        scopedSlots: _vm._u([{
          key: "default",
          fn: function fn(_ref) {
            var hover = _ref.hover;
            return [_c('div', {
              key: "".concat(_vm.isLoading),
              class: inputFieldConfig.category === _vm.customFieldGroup ? '' : 'd-flex align-center'
            }, [inputFieldConfig.type === _vm.DetailFormComponentsEnum.ARRAY ? _c('m-detail-form-array-form', _vm._b({
              attrs: {
                "outerConfig": inputFieldConfig,
                "search": _vm.search,
                "partnerId": _vm.partnerId
              },
              on: {
                "input": function input($event) {
                  return _vm.onInput(inputFieldConfig.key, $event);
                }
              },
              model: {
                value: inputFieldConfig.model,
                callback: function callback($$v) {
                  _vm.$set(inputFieldConfig, "model", $$v);
                },
                expression: "inputFieldConfig.model"
              }
            }, 'm-detail-form-array-form', inputFieldConfig.props, false)) : _vm.getIsConditionSatisfied(inputFieldConfig.condition) ? _c(inputFieldConfig.type, _vm._b({
              tag: "component",
              attrs: {
                "outlined": true,
                "partnerId": _vm.partnerId,
                "dense": false,
                "css": "width:100%",
                "readonly": _vm.readonly || inputFieldConfig.props && inputFieldConfig.props.readonly,
                "disabled": _vm.disabled || inputFieldConfig.props && inputFieldConfig.props.disabled
              },
              on: {
                "input": function input($event) {
                  return _vm.onInput(inputFieldConfig.key, $event);
                }
              },
              model: {
                value: inputFieldConfig.model,
                callback: function callback($$v) {
                  _vm.$set(inputFieldConfig, "model", $$v);
                },
                expression: "inputFieldConfig.model"
              }
            }, 'component', inputFieldConfig.props, false)) : _vm._e(), _vm.showClearButton(hover, inputFieldConfig) ? _c('v-btn', {
              staticClass: "mt-n4 mb-4",
              attrs: {
                "icon": ""
              },
              on: {
                "click": function click($event) {
                  $event.stopPropagation();
                  return _vm.clearLeaf(inputFieldConfig.key);
                }
              }
            }, [_c('v-icon', [_vm._v(" mdi-close ")])], 1) : _vm._e()], 1)];
          }
        }], null, true)
      })], 1);
    }), 0)], 1)], 1);
  }), _vm._t("extraPanels")], 2), !_vm.autoSync && _vm.containButtons ? _c('v-slide-x-reverse-transition', [_vm.changesDetected || _vm.forceSyncButton ? _c('v-card-actions', {
    staticClass: "px-4"
  }, [_vm._t("left"), _c('v-btn', {
    staticStyle: {
      "width": "30%"
    },
    attrs: {
      "elevation": 0,
      "x-large": "",
      "data-test-delete-file": ""
    },
    on: {
      "click": _vm.abort
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]), _c('v-spacer'), _c('v-btn', {
    staticStyle: {
      "width": "60%"
    },
    attrs: {
      "loading": _vm.loading,
      "disabled": !_vm.isValid,
      "color": "success",
      "elevation": 0,
      "x-large": ""
    },
    on: {
      "click": _vm.sync
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")])], 2) : _vm._e()], 1) : _vm._e()], 1), !_vm.autoSync && !_vm.containButtons ? _c('v-slide-x-reverse-transition', [_vm.changesDetected || _vm.forceSyncButton ? _c('v-card', {
    staticClass: "pa-4 changes-detected-card"
  }, [_c('v-btn', {
    staticClass: "mr-2",
    attrs: {
      "elevation": "0",
      "color": "info",
      "disabled": !_vm.isValid
    },
    on: {
      "click": _vm.sync
    }
  }, [_vm._v(_vm._s(_vm.$t("save")))]), _c('v-btn', {
    staticClass: "mr-2",
    attrs: {
      "outlined": "",
      "color": "info"
    },
    on: {
      "click": _vm.abort
    }
  }, [_vm._v(_vm._s(_vm.$t("cancel")))]), _c('span', {
    staticClass: "text-body-2"
  }, [_vm._v(_vm._s(_vm.$t("designGuide.MDetailForm.changes")))])], 1) : _vm._e()], 1) : _vm._e(), _c('debug', [_c('v-card-title', [_vm._v("Input Object (item)")]), _c('div', [_vm._v(" " + _vm._s(_vm.item) + " ")]), _c('v-card-title', [_vm._v("Configuration")]), _c('div', [_vm._v(" " + _vm._s(_vm.config) + " ")]), _c('v-select'), _c('v-text-field')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }