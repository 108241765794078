var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('timeline-card', {
    attrs: {
      "title": _vm.$t('report.broker.title'),
      "divider": true
    },
    scopedSlots: _vm._u([_vm.canUpdateReport ? {
      key: "menu",
      fn: function fn() {
        return [_c('partner-report-update-report-dialog', {
          attrs: {
            "config": [_vm.ReportUpdateTabEnum.broker],
            "activityTarget": "broker"
          }
        })];
      },
      proxy: true
    } : null], null, true)
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-simple-table', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_c('tbody', [_c('tr', [_c('td', [_vm._v(_vm._s(_vm.$t("report.broker.name")))]), _c('td', [_vm._v(_vm._s(_vm.broker.name))])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.$t("report.broker.number")))]), _c('td', [_vm._v(_vm._s(_vm.broker.number))])])])];
      },
      proxy: true
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }