import { render, staticRenderFns } from "./PartnerMessageDetailAttachmentRow.vue?vue&type=template&id=67a0d268&scoped=true&"
import script from "./PartnerMessageDetailAttachmentRow.vue?vue&type=script&lang=ts&"
export * from "./PartnerMessageDetailAttachmentRow.vue?vue&type=script&lang=ts&"
import style0 from "./PartnerMessageDetailAttachmentRow.vue?vue&type=style&index=0&id=67a0d268&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67a0d268",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCheckbox,VSpacer})


/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Resize from 'vuetify/lib/directives/resize'
installDirectives(component, {Resize})
