var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-menu', {
    attrs: {
      "close-on-content-click": false
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('div', {
          staticClass: "flex-grow-1"
        }, [!_vm.localValue || !_vm.localValue.length ? _c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "value": '',
            "readonly": "",
            "outlined": "",
            "label": _vm.label,
            "required": _vm.required,
            "rules": _vm.rules
          }
        }, 'v-text-field', attrs, false), on)) : _c('div', [_c('div', {
          staticClass: "d-flex mt-2 mb-n2"
        }, [_c('small', {
          staticClass: "v-label v-label--active theme--light ml-2"
        }, [_vm._v(_vm._s(_vm.$t(_vm.label)))]), _c('v-spacer'), _vm.localValue && _vm.localValue.length ? _c('v-btn', _vm._g(_vm._b({
          attrs: {
            "text": "",
            "x-small": ""
          }
        }, 'v-btn', attrs, false), on), [_vm._v(_vm._s(_vm.$t("add")))]) : _vm._e()], 1), _c('refs-selected', {
          staticClass: "mb-10",
          attrs: {
            "partnerId": _vm.partnerId,
            "removable": true,
            "confirmable": false,
            "outlined": true,
            "draggable": false
          },
          on: {
            "removed": _vm.onRemoved
          },
          model: {
            value: _vm.localValue,
            callback: function callback($$v) {
              _vm.localValue = $$v;
            },
            expression: "localValue"
          }
        })], 1)], 1)];
      }
    }]),
    model: {
      value: _vm.isMenu,
      callback: function callback($$v) {
        _vm.isMenu = $$v;
      },
      expression: "isMenu"
    }
  }, [_c('v-card', {
    staticClass: "pa-1",
    attrs: {
      "outlined": ""
    }
  }, [_c('refs-selection', {
    attrs: {
      "partnerId": _vm.partnerId,
      "categories": _vm.categories,
      "outlined": false,
      "hideSuggestions": true
    },
    on: {
      "input": function input(v) {
        _vm.localValue = v;
        _vm.isMenu = false;
      }
    },
    model: {
      value: _vm.localValue,
      callback: function callback($$v) {
        _vm.localValue = $$v;
      },
      expression: "localValue"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }