var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pb-14"
  }, [_c('m-header', {
    attrs: {
      "title": _vm.value.title,
      "breadCrumbs": _vm.breadCrumbs,
      "actions": _vm.isEditTitle ? [] : _vm.actions
    },
    on: {
      "actionClicked": function actionClicked($event) {
        return $event.exec();
      },
      "alertClicked": function alertClicked($event) {
        return $event.exec();
      }
    },
    scopedSlots: _vm._u([_vm.isEditTitle ? {
      key: "title",
      fn: function fn() {
        return [_c('v-text-field', {
          staticClass: "mt-1 mb-n1",
          attrs: {
            "label": _vm.$t('common.nouns.title'),
            "outlined": "",
            "loading": _vm.isLoadingTitle,
            "readonly": _vm.isLoadingTitle
          },
          model: {
            value: _vm.editTitleCopy,
            callback: function callback($$v) {
              _vm.editTitleCopy = $$v;
            },
            expression: "editTitleCopy"
          }
        }), _c('div', {
          staticClass: "mt-n6"
        }, [_c('v-btn', {
          staticClass: "mr-1",
          attrs: {
            "x-small": "",
            "elevation": 0,
            "color": "info"
          },
          on: {
            "click": _vm.saveEditTitle
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")]), _c('v-btn', {
          attrs: {
            "x-small": "",
            "elevation": 0,
            "color": "info",
            "outlined": ""
          },
          on: {
            "click": _vm.cancelEditTitle
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")])], 1)];
      },
      proxy: true
    } : null], null, true)
  }), !_vm.isEditTitle ? _c('div', {
    staticClass: "mt-n6 mx-4"
  }, [_c('debug', [_vm._v(" value total " + _vm._s(_vm.value.total)), _c('br'), _vm._v(" value absoluteTotal " + _vm._s(_vm.value.absoluteTotal)), _c('br'), _vm._v(" value expenseOrIncome " + _vm._s(_vm.value.expenseOrIncome)), _c('br')]), _c('v-menu', {
    attrs: {
      "close-on-content-click": false
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('span', _vm._g(_vm._b({}, 'span', attrs, false), on), [_c('cost-chip', {
          attrs: {
            "value": _vm.value,
            "large": true
          },
          on: {
            "click": _vm.startEditTotal
          }
        })], 1)];
      }
    }], null, false, 3129546441),
    model: {
      value: _vm.isEditAbsoluteTotal,
      callback: function callback($$v) {
        _vm.isEditAbsoluteTotal = $$v;
      },
      expression: "isEditAbsoluteTotal"
    }
  }, [_vm.isEditAbsoluteTotal ? _c('v-card', {
    staticClass: "pt-2 px-2 ",
    attrs: {
      "width": "260px",
      "elevation": 0,
      "loading": _vm.isLoadingTotal
    }
  }, [_c('v-radio-group', {
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.typeCopy,
      callback: function callback($$v) {
        _vm.typeCopy = $$v;
      },
      expression: "typeCopy"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": _vm.$t('common.nouns.expense'),
      "value": _vm.CostTypeEnum.EXPENSE
    }
  }), _c('v-radio', {
    attrs: {
      "label": _vm.$t('common.nouns.credit'),
      "value": _vm.CostTypeEnum.INCOME
    }
  })], 1), _c('money-input', {
    staticClass: "mx-1 ",
    attrs: {
      "label": _vm.$t('objects.cost.total')
    },
    model: {
      value: _vm.absoluteTotalCopy,
      callback: function callback($$v) {
        _vm.absoluteTotalCopy = $$v;
      },
      expression: "absoluteTotalCopy"
    }
  }), _c('v-card-actions', {
    staticClass: "mt-n5"
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "x-small": "",
      "text": ""
    },
    domProps: {
      "textContent": _vm._s(_vm.$t('cancel'))
    },
    on: {
      "click": _vm.abortEditTotal
    }
  }), _c('v-btn', {
    attrs: {
      "x-small": "",
      "text": "",
      "color": "info",
      "loading": _vm.isLoadingTotal
    },
    domProps: {
      "textContent": _vm._s(_vm.$t('save'))
    },
    on: {
      "click": _vm.saveTotal
    }
  })], 1), _c('v-spacer')], 1) : _vm._e()], 1), _c('debug', [_vm._v(" value date " + _vm._s(_vm.value.date)), _c('br')])], 1) : _vm._e(), _c('v-divider', {
    staticClass: "mt-2 mb-n4"
  }), _c('m-detail-view-grid', {
    scopedSlots: _vm._u([{
      key: "details",
      fn: function fn() {
        return [_c('debug', [_vm._v("value.date " + _vm._s(_vm.value.date))]), _c('v-text-field', {
          attrs: {
            "loading": _vm.isLoadingDate,
            "type": "date",
            "outlined": "",
            "label": _vm.$t('objects.cost.date')
          },
          on: {
            "change": _vm.saveDate
          },
          model: {
            value: _vm.date,
            callback: function callback($$v) {
              _vm.date = $$v;
            },
            expression: "date"
          }
        }), _c('v-combobox', {
          attrs: {
            "label": _vm.$t('objects.cost.tags'),
            "multiple": "",
            "outlined": "",
            "clearable": "",
            "items": _vm.tags
          },
          on: {
            "change": _vm.saveTags
          },
          scopedSlots: _vm._u([{
            key: "selection",
            fn: function fn(_ref2) {
              var item = _ref2.item;
              return [_c('v-chip', _vm._b({
                attrs: {
                  "outlined": ""
                }
              }, 'v-chip', item.attrs, false), [_vm._v(" " + _vm._s(item) + " ")])];
            }
          }, {
            key: "item",
            fn: function fn(_ref3) {
              var item = _ref3.item;
              return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(item) + " ")])], 1)];
            }
          }]),
          model: {
            value: _vm.value.tags,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "tags", $$v);
            },
            expression: "value.tags"
          }
        }), _c('v-select', {
          staticClass: "mt-2",
          attrs: {
            "item-value": "id",
            "items": _vm.groups,
            "label": _vm.$t('objects.cost.group'),
            "outlined": "",
            "clearable": "",
            "loading": _vm.isLoadingGroup
          },
          on: {
            "change": _vm.saveGroup
          },
          scopedSlots: _vm._u([{
            key: "append",
            fn: function fn() {
              return [_c('cost-group-create-dialog', {
                attrs: {
                  "partnerId": _vm.value.partnerId
                },
                on: {
                  "created": function created(costGroup) {
                    return _vm.value.group = costGroup.id;
                  }
                },
                scopedSlots: _vm._u([{
                  key: "activator",
                  fn: function fn(_ref4) {
                    var on = _ref4.on;
                    return [_c('v-btn', {
                      staticStyle: {
                        "position": "relative",
                        "top": "-6px"
                      },
                      attrs: {
                        "icon": "",
                        "loading": _vm.isLoadingCostGroups
                      },
                      on: {
                        "click": function click($event) {
                          $event.stopPropagation();
                          return on.apply(null, arguments);
                        }
                      }
                    }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)];
                  }
                }])
              })];
            },
            proxy: true
          }, {
            key: "item",
            fn: function fn(_ref5) {
              var item = _ref5.item;
              return [_vm._v(" " + _vm._s(item.title) + " ")];
            }
          }, {
            key: "selection",
            fn: function fn(_ref6) {
              var item = _ref6.item;
              return [_vm._v(" " + _vm._s(item.title) + " ")];
            }
          }]),
          model: {
            value: _vm.value.group,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "group", $$v);
            },
            expression: "value.group"
          }
        }), _c('div', {
          staticClass: "mt-n7",
          staticStyle: {
            "display": "flex"
          }
        }, [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "x-small": "",
            "text": "",
            "elevation": 0,
            "loading": _vm.isLoadingCostGroups
          },
          on: {
            "click": _vm.refreshCostGroups
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("common.verbs.refresh")) + " ")]), _c('v-btn', {
          attrs: {
            "x-small": "",
            "text": "",
            "elevation": 0
          },
          on: {
            "click": _vm.goToCostGroupCustomView
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("common.verbs.view")) + " ")])], 1), _vm.costGroup && _vm.costGroup.configuration && _vm.costGroup.configuration.customFieldConfig && _vm.costGroup.configuration.customFieldConfig.length ? _c('div', [_vm.isLoadingCustomFieldValues ? _c('v-progress-linear', {
          attrs: {
            "indeterminate": ""
          }
        }) : _c('v-divider'), _c('custom-field-list-form', {
          staticClass: "mt-6",
          attrs: {
            "customFieldConfig": _vm.costGroup.configuration.customFieldConfig
          },
          on: {
            "change": _vm.debounceSaveCustomFields
          },
          model: {
            value: _vm.value.values,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "values", $$v);
            },
            expression: "value.values"
          }
        })], 1) : _vm._e()];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('v-divider', {
          staticClass: "mt-6"
        }), _c('m-action-list', {
          attrs: {
            "actions": _vm.bottomActions
          },
          on: {
            "actionClicked": function actionClicked($event) {
              return $event.exec();
            }
          }
        })];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "mt-1"
  }, [_c('div', {
    staticClass: "mx-2",
    staticStyle: {
      "display": "flex"
    }
  }, [_c('small', {
    staticClass: "ml-1"
  }, [_vm._v(" " + _vm._s(_vm.subtitle))]), _c('v-spacer'), !_vm.isEditDescription ? _c('v-btn', {
    attrs: {
      "elevation": 0,
      "text": "",
      "x-small": ""
    },
    domProps: {
      "textContent": _vm._s(_vm.$t('edit'))
    },
    on: {
      "click": _vm.startEditDescription
    }
  }) : _vm._e()], 1), _c('v-textarea', {
    staticClass: "mx-2",
    staticStyle: {
      "height": "180px"
    },
    attrs: {
      "loading": _vm.isLoadingDescription,
      "readonly": !_vm.isEditDescription || _vm.isLoadingDescription,
      "outlined": true
    },
    model: {
      value: _vm.descriptionCopy,
      callback: function callback($$v) {
        _vm.descriptionCopy = $$v;
      },
      expression: "descriptionCopy"
    }
  }), _vm.isEditDescription ? _c('div', {
    staticClass: "mt-n2 mx-2",
    staticStyle: {
      "display": "flex"
    }
  }, [_c('v-btn', {
    staticClass: "mr-2",
    attrs: {
      "elevation": 0,
      "small": "",
      "color": "info"
    },
    domProps: {
      "textContent": _vm._s(_vm.$t('save'))
    },
    on: {
      "click": _vm.saveEditDescription
    }
  }), _c('v-btn', {
    attrs: {
      "elevation": 0,
      "small": "",
      "outlined": "",
      "color": "info"
    },
    domProps: {
      "textContent": _vm._s(_vm.$t('close'))
    },
    on: {
      "click": _vm.abortEditDesciption
    }
  })], 1) : _vm._e()], 1), _c('cost-card-documents', {
    staticClass: "mx-2 mt-3",
    attrs: {
      "value": _vm.value.files,
      "partnerId": _vm.value.partnerId,
      "costId": _vm.value.id,
      "loading": _vm.isLoadingFiles
    },
    on: {
      "input": _vm.saveFiles
    }
  }), _c('refs-select', {
    staticClass: "mx-2",
    attrs: {
      "partnerId": _vm.value.partnerId,
      "loading": _vm.isLoadingRefs,
      "suggested": [],
      "listMode": false,
      "hideEditButton": false,
      "categories": _vm.refsCategories,
      "hideSuggestions": true
    },
    on: {
      "confirm": _vm.saveRefs,
      "change": _vm.saveRefs,
      "input": _vm.saveRefs
    },
    model: {
      value: _vm.value.refs,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "refs", $$v);
      },
      expression: "value.refs"
    }
  }), _c('v-divider', {
    staticClass: "mt-2"
  }), _c('activity-card', {
    attrs: {
      "partner": _vm.partner,
      "source": _vm.source,
      "config": {
        comment: true,
        mail: false
      }
    }
  })], 1), _vm.isDeleteDialogActive && _vm.value ? _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isDeleteDialogActive,
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown,
      "title": _vm.$t('partner.PartnerReportDetailChangeProgress.confirmDeleteTitle'),
      "rightColor": 'error'
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "close": function close($event) {
        _vm.isDeleteDialogActive = false;
      },
      "leftClick": function leftClick($event) {
        _vm.isDeleteDialogActive = false;
      },
      "rightClick": _vm.onDelete
    }
  }, [_c('refs-cost', {
    attrs: {
      "item": _vm.value
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }