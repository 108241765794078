import { render, staticRenderFns } from "./RefsMessage.vue?vue&type=template&id=4009c97d&scoped=true&"
import script from "./RefsMessage.vue?vue&type=script&lang=ts&"
export * from "./RefsMessage.vue?vue&type=script&lang=ts&"
import style0 from "./RefsMessage.vue?vue&type=style&index=0&id=4009c97d&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4009c97d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VIcon,VListItem,VListItemAction,VListItemContent,VListItemSubtitle,VListItemTitle,VSpacer})
