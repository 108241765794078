var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-stepper', {
    attrs: {
      "flat": ""
    },
    model: {
      value: _vm.step,
      callback: function callback($$v) {
        _vm.step = $$v;
      },
      expression: "step"
    }
  }, [_c('v-stepper-content', {
    attrs: {
      "step": _vm.STEPS.OVERVIEW
    }
  }, [_vm._l(_vm.sharedSorted, function (content, index) {
    return _c('div', {
      key: 'sharedSorted' + content.id
    }, [content.isLoading ? _c('v-skeleton-loader', {
      attrs: {
        "type": "list-item-two-line"
      }
    }) : content.isNotFound ? _c('div') : _c('v-hover', {
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref) {
          var hover = _ref.hover;
          return [_c('div', [_c('v-list-item', {
            on: {
              "click": function click($event) {
                _vm.sharedContent = content;
              }
            }
          }, [_c('v-list-item-icon', [!content.screenOrder ? _c('v-icon', [_vm._v(" mdi-share ")]) : _c('v-icon', [_vm._v(" mdi-paperclip ")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [content.isExpired ? _c('v-chip', {
            attrs: {
              "color": "error",
              "small": ""
            }
          }, [_vm._v(" " + _vm._s(_vm.$t("common.states.expired")) + " ")]) : _c('v-chip', {
            attrs: {
              "color": "success",
              "small": ""
            }
          }, [_vm._v(" " + _vm._s(_vm.$t("common.states.active")) + " ")]), content.isSystemGenerated ? _c('v-chip', {
            staticClass: "ml-1",
            attrs: {
              "small": ""
            }
          }, [_vm._v(" " + _vm._s(_vm.$t("timeLine.ActivityTimeLineItemDocument.systemgenerated")) + " ")]) : content.userId && _vm.getUserNameForId(content.userId) ? _c('v-chip', {
            staticClass: "ml-1",
            attrs: {
              "small": ""
            }
          }, [_vm._v(" " + _vm._s(_vm.getUserNameForId(content.userId)) + " ")]) : _vm._e()], 1), content.title ? _c('v-list-item-title', [_vm._v(" " + _vm._s(content.title) + " ")]) : _vm._e(), content.description ? _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(content.description) + " ")]) : _vm._e(), content.validBy ? _c('v-list-item-subtitle', {
            attrs: {
              "small": ""
            }
          }, [_vm._v(" " + _vm._s(_vm.$t("timeLine.ActivityTimeLineItemShare.validBy", {
            date: _vm.simpleDate(content.validBy)
          })) + " ")]) : _c('v-list-item-subtitle', {
            attrs: {
              "small": ""
            }
          }, [_vm._v(" " + _vm._s(_vm.$t("timeLine.ActivityTimeLineItemShare.validIndefinitely")) + " ")])], 1), _c('v-list-item-action', [hover && !content.isExpired ? _c('v-btn', {
            attrs: {
              "text": ""
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                $event.stopPropagation();
                return function () {
                  return content.copyLinkToClipboard();
                }.apply(null, arguments);
              }
            }
          }, [_vm._v(" " + _vm._s(_vm.$t("timeLine.ActivityTimeLineItemShare.btnText")) + " ")]) : _vm._e()], 1), !content.screenOrder ? _c('v-list-item-icon', [_c('v-badge', {
            attrs: {
              "content": content.refs.length
            }
          }, [_c('v-icon', {
            attrs: {
              "large": ""
            }
          }, [_vm._v(" mdi-file ")])], 1)], 1) : _vm._e()], 1), index !== _vm.sharedSorted.length - 1 ? _c('v-divider') : _vm._e()], 1)];
        }
      }], null, true)
    })], 1);
  }), _vm.sharedContent && !_vm.sharedContent.screenOrder ? _c('shared-content-side-card', {
    attrs: {
      "hideSuggested": false,
      "value": _vm.sharedContent
    },
    on: {
      "close": function close($event) {
        _vm.sharedContent = null;
      }
    }
  }) : _vm.sharedContent && _vm.sharedContent.screenOrder ? _c('attachment-request-side-card', {
    attrs: {
      "value": _vm.sharedContent
    },
    on: {
      "close": function close($event) {
        _vm.sharedContent = null;
      }
    }
  }) : _vm._e()], 2), _c('v-stepper-content', {
    attrs: {
      "step": _vm.STEPS.DATE
    }
  }, [_c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', {
    domProps: {
      "textContent": _vm._s('mdi-information')
    }
  })], 1), _c('v-list-item-content', [_c('v-list-item-subtitle', {
    staticClass: "text-wrap",
    domProps: {
      "textContent": _vm._s(_vm.$t('timeLine.ReportActivityBoxShareComponent.title'))
    }
  })], 1)], 1), _c('v-list-item', [_c('v-list-item-content', [_c('v-switch', {
    staticClass: "mx-2",
    attrs: {
      "label": _vm.isExpiringLink ? _vm.$t('timeLine.ReportActivityBoxShareComponent.expiringLink') : _vm.$t('timeLine.ReportActivityBoxShareComponent.nonExpiringLink')
    },
    model: {
      value: _vm.isExpiringLink,
      callback: function callback($$v) {
        _vm.isExpiringLink = $$v;
      },
      expression: "isExpiringLink"
    }
  }), _vm.isExpiringLink ? _c('v-list-item-title', {
    staticClass: "ma-n2",
    domProps: {
      "textContent": _vm._s(_vm.$t('timeLine.ReportActivityBoxShareComponent.question'))
    }
  }) : _vm._e(), _vm.isExpiringLink ? _c('v-date-picker', {
    staticClass: "ma-n2",
    attrs: {
      "min": _vm.minimumExpirationDate,
      "no-title": "",
      "scrollable": "",
      "color": "primary",
      "first-day-of-week": "1",
      "locale": _vm.$t('report.datePicker.locale')
    },
    model: {
      value: _vm.validBy,
      callback: function callback($$v) {
        _vm.validBy = $$v;
      },
      expression: "validBy"
    }
  }) : _vm._e(), _vm.isExpiringLink ? _c('v-card-subtitle', {
    domProps: {
      "textContent": _vm._s(_vm.$t('timeLine.ReportActivityBoxShareComponent.validForAnotherDays', {
        days: _vm.daysUntilExpirations
      }))
    }
  }) : _vm._e()], 1)], 1)], 1), _c('v-stepper-content', {
    attrs: {
      "step": _vm.STEPS.IS_EDIT
    }
  }, [_c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', {
    domProps: {
      "textContent": _vm._s('mdi-information')
    }
  })], 1), _c('v-list-item-content', [_c('v-list-item-subtitle', {
    staticClass: "text-wrap",
    domProps: {
      "textContent": _vm._s(_vm.$t('timeLine.ReportActivityBoxShareComponent.editExplanation'))
    }
  })], 1)], 1), _c('v-row', _vm._l(_vm.choices, function (choice) {
    return _c('v-col', {
      key: choice.subtitle,
      attrs: {
        "cols": "12",
        "sm": "6"
      }
    }, [_c('v-item', [_c('v-sheet', {
      staticClass: "pointer",
      attrs: {
        "outlined": "",
        "rounded": "",
        "color": "info"
      },
      on: {
        "click": choice.onClick
      }
    }, [_c('v-card', {
      attrs: {
        "outlined": ""
      }
    }, [_c('v-card-actions', {
      staticClass: "pt-8 pb-8"
    }, [_c('v-spacer'), _c('v-icon', {
      staticStyle: {
        "transform": "scale(2)"
      },
      attrs: {
        "x-large": "",
        "color": "info"
      }
    }, [_vm._v(" " + _vm._s(choice.icon) + " ")]), _c('v-spacer')], 1), _c('h3', {
      staticClass: "text-center mb-n2"
    }, [_vm._v(" " + _vm._s(choice.title) + " ")]), _c('v-card-subtitle', {
      staticClass: "text-center"
    }, [_vm._v(" " + _vm._s(choice.subtitle) + " ")])], 1)], 1)], 1)], 1);
  }), 1)], 1), _c('v-stepper-content', {
    attrs: {
      "step": _vm.STEPS.APPENDIXES
    }
  }, [_c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', {
    domProps: {
      "textContent": _vm._s('mdi-attachment')
    }
  })], 1), _c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "text-wrap",
    domProps: {
      "textContent": _vm._s(_vm.$t('timeLine.ReportActivityBoxShareComponent.shareTitle'))
    }
  })], 1)], 1), _c('table-wrapper', {
    attrs: {
      "loading": _vm.isLoading || _vm.isLoadingDocuments,
      "allItems": _vm.documents,
      "selectedItems": _vm.selectedAttachments,
      "headers": _vm.headers,
      "showSelect": true,
      "singleSelect": false,
      "margin": false,
      "itemsPerPage": 8
    },
    on: {
      "update:selectedItems": function updateSelectedItems($event) {
        _vm.selectedAttachments = $event;
      },
      "update:selected-items": function updateSelectedItems($event) {
        _vm.selectedAttachments = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.isTemplate",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticStyle: {
            "display": "flex"
          }
        }, [_c('v-tooltip', {
          attrs: {
            "right": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var on = _ref3.on;
              return [_c('v-icon', _vm._g({}, on), [_vm._v(" " + _vm._s(item.isSigned ? "mdi-signature-freehand" : item.isTemplate ? "mdi-file-multiple-outline" : "mdi-file-document-outline") + " ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v(" " + _vm._s(item.isSigned ? _vm.$t("sign.DocumentDetail.signed") : item.isTemplate ? _vm.$t("sign.DocumentDetail.template") : _vm.$t("sign.DocumentDetail.file")) + " ")])])], 1)];
      }
    }, {
      key: "item.onClick",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('v-btn', {
          attrs: {
            "small": "",
            "text": "",
            "outlined": ""
          },
          on: {
            "click": function click($event) {
              return _vm.scrollToDocument(item.id);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("timeLine.ReportActivityBoxShareComponent.look")) + " ")])];
      }
    }])
  })], 1), _c('v-stepper-content', {
    attrs: {
      "step": _vm.STEPS.EDIT_STEPS
    }
  }, [_c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', {
    domProps: {
      "textContent": _vm._s('mdi-format-list-numbered')
    }
  })], 1), _c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "text-wrap",
    domProps: {
      "textContent": _vm._s(_vm.$t('timeLine.ReportActivityBoxShareComponent.screenOrderQuestion'))
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "required": "",
      "rules": _vm.requiredRule,
      "label": 'titel'
    },
    model: {
      value: _vm.sharedName,
      callback: function callback($$v) {
        _vm.sharedName = $$v;
      },
      expression: "sharedName"
    }
  }), _c('report-activity-box-share-component-screen-order', {
    attrs: {
      "screenSelection": _vm.screenSelection
    }
  }), _c('div', {
    staticClass: "mx-4",
    staticStyle: {
      "display": "flex",
      "align-items": "center"
    }
  }, [_c('v-select', {
    attrs: {
      "outlined": "",
      "items": _vm.unselectedSelectableScreens,
      "disabled": !_vm.unselectedSelectableScreens.length && !_vm.newScreen
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('v-list-item-content', [_c('v-list-item-title', {
          domProps: {
            "textContent": _vm._s(_vm.$t("screenEnum.".concat(item, ".title")))
          }
        }), _c('v-list-item-subtitle', {
          domProps: {
            "textContent": _vm._s(_vm.$t("screenEnum.".concat(item, ".description")))
          }
        })], 1)];
      }
    }, {
      key: "selection",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('v-list-item-content', [_c('v-list-item-title', {
          domProps: {
            "textContent": _vm._s(_vm.$t("screenEnum.".concat(item, ".title")))
          }
        }), _c('v-list-item-subtitle', {
          domProps: {
            "textContent": _vm._s(_vm.$t("screenEnum.".concat(item, ".description")))
          }
        })], 1)];
      }
    }]),
    model: {
      value: _vm.newScreen,
      callback: function callback($$v) {
        _vm.newScreen = $$v;
      },
      expression: "newScreen"
    }
  }), _c('v-btn', {
    staticClass: " ml-1 mb-4 mt-n4",
    attrs: {
      "icon": "",
      "disabled": !_vm.newScreen
    },
    on: {
      "click": _vm.addNewScreen
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)], 1)], 1)], 1)], 1), _c('v-stepper-content', {
    attrs: {
      "step": _vm.STEPS.REQUEST_SUCCESS
    }
  }, [_c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', {
    domProps: {
      "textContent": _vm._s('mdi-information')
    }
  })], 1), _c('v-list-item-content', [_c('v-list-item-subtitle', {
    staticClass: "text-wrap",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('timeLine.ReportActivityBoxShareComponent.requestWarning'))
    }
  })], 1)], 1), _c('v-img', {
    staticClass: "mt-5 mb-5",
    attrs: {
      "align": "center",
      "justify": "center",
      "contain": "",
      "max-height": "300px",
      "src": require("@/assets/undraw/undraw_add_files_re_v09g.svg")
    }
  })], 1), _c('v-stepper-content', {
    attrs: {
      "step": _vm.STEPS.SHARING_SUCCESS
    }
  }, [_c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', {
    domProps: {
      "textContent": _vm._s('mdi-information')
    }
  })], 1), _c('v-list-item-content', [_c('v-list-item-subtitle', {
    staticClass: "text-wrap",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('timeLine.ReportActivityBoxShareComponent.shareWarning'))
    }
  })], 1)], 1), _c('v-img', {
    staticClass: "mt-5 mb-5",
    attrs: {
      "align": "center",
      "justify": "center",
      "contain": "",
      "max-height": "300px",
      "src": require("@/assets/undraw/undraw_add_files_re_v09g.svg")
    }
  })], 1)], 1), !_vm.hideButton ? _c('v-card-actions', [_vm.stepActions.isPrevious ? _c('v-btn', {
    attrs: {
      "text": "",
      "loading": _vm.isLoading,
      "small": "",
      "elevation": "0"
    },
    domProps: {
      "textContent": _vm._s(_vm.stepActions.previousLabel || _vm.$t('components.template.dialog.preview.back'))
    },
    on: {
      "click": _vm.stepActions.onPrevious
    }
  }) : _vm._e(), _vm.stepActions.isNext ? _c('v-btn', {
    attrs: {
      "color": "success",
      "loading": _vm.isLoading,
      "small": "",
      "elevation": "0"
    },
    domProps: {
      "textContent": _vm._s(_vm.stepActions.nextLabel || _vm.$t('components.template.dialog.preview.next'))
    },
    on: {
      "click": _vm.stepActions.onNext
    }
  }) : _vm._e()], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }