var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('Can', {
    attrs: {
      "I": _vm.ActionEnum.DELETE,
      "a": _vm.ResourceEnum.ACTIVITY,
      "field": _vm.partnerId
    }
  }, [_vm.text ? _c('v-list-item', {
    on: {
      "click": function click($event) {
        _vm.isDeleteDialogActive = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.partner.ActivityTimeLineItemDelete.delete")) + " ")]) : _c('v-btn', {
    staticClass: "mr-1",
    staticStyle: {
      "margin-top": "-3px"
    },
    attrs: {
      "dense": "",
      "icon": "",
      "loading": _vm.isLoading,
      "color": "grey lighten-1"
    },
    on: {
      "click": function click($event) {
        _vm.isDeleteDialogActive = true;
      }
    }
  }, [_c('v-icon', [_vm._v(" mdi-trash-can ")])], 1), _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isDeleteDialogActive,
      "title": _vm.$t('components.partner.ActivityTimeLineItemDelete.title'),
      "rightText": _vm.$t('components.partner.ActivityTimeLineItemDelete.delete'),
      "leftDisabled": _vm.isLoading,
      "rightDisabled": _vm.isLoading,
      "leftLoading": _vm.isLoading,
      "rightLoading": _vm.isLoading
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "leftClick": function leftClick($event) {
        _vm.isDeleteDialogActive = false;
      },
      "rightClick": _vm.deleteActivity
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.partner.ActivityTimeLineItemDelete.description")) + " ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }