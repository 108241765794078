var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-menu', {
    attrs: {
      "close-on-content-click": false
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('div', {
          staticClass: "flex-grow-1"
        }, [!_vm.localValue || !_vm.localValue.length ? _c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "value": '',
            "readonly": "",
            "outlined": "",
            "label": _vm.label,
            "required": _vm.required,
            "rules": _vm.rules
          }
        }, 'v-text-field', attrs, false), on)) : _c('div', [_c('div', {
          staticClass: "d-flex mt-1 mb-n1"
        }, [_c('small', {
          staticClass: "v-label v-label--active theme--light ml-2"
        }, [_vm._v(" " + _vm._s(_vm.$t(_vm.label)) + " ")]), _c('v-spacer'), _c('v-btn', _vm._g(_vm._b({
          attrs: {
            "text": "",
            "x-small": ""
          }
        }, 'v-btn', attrs, false), on), [_vm._v(" " + _vm._s(_vm.$t("add")) + " ")])], 1), _c('refs-selected', {
          key: 'refs-selected-' + _vm.refType,
          staticClass: "mb-10",
          attrs: {
            "value": _vm.localValue.map(function (v) {
              return {
                refId: v,
                refType: _vm.refType
              };
            }),
            "partnerId": _vm.partnerId,
            "removable": true,
            "confirmable": false,
            "outlined": true,
            "draggable": false
          },
          on: {
            "removed": _vm.onRemoved
          }
        })], 1)], 1)];
      }
    }]),
    model: {
      value: _vm.isMenu,
      callback: function callback($$v) {
        _vm.isMenu = $$v;
      },
      expression: "isMenu"
    }
  }, [_c('v-card', {
    staticClass: "pa-1"
  }, [_c('refs-selection-by-type', {
    attrs: {
      "refType": _vm.refType,
      "partnerId": _vm.partnerId,
      "showAsMenu": false
    },
    on: {
      "select": _vm.onSelect
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }