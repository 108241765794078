var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('timeline-card', {
    attrs: {
      "title": _vm.title
    },
    scopedSlots: _vm._u([{
      key: "menu",
      fn: function fn() {
        return [_vm.deleteUpdateActivity ? _c('div', [_vm.$vuetify.breakpoint.smAndDown ? _c('context-menu', [_c('v-list', [_c('v-list-item', {
          on: {
            "click": function click($event) {
              _vm.isDeleteDialogActive = true;
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("delete")) + " ")])], 1)], 1) : _c('div', {
          staticStyle: {
            "display": "flex",
            "align-items": "center"
          }
        }, [_c('v-btn', {
          staticClass: "mt-n1",
          attrs: {
            "x-small": "",
            "outlined": "",
            "color": "info",
            "disabled": !_vm.message
          },
          on: {
            "click": function click($event) {
              _vm.isMessageSideCardOpen = true;
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("timeLine.ActivityTimeLineItemTicket.detail")) + " ")]), _c('v-btn', {
          staticClass: "mr-1",
          staticStyle: {
            "margin-top": "-3px"
          },
          attrs: {
            "dense": "",
            "icon": "",
            "loading": _vm.isDeleteDialogLoading,
            "color": "grey lighten-1"
          },
          on: {
            "click": function click($event) {
              _vm.isDeleteDialogActive = true;
            }
          }
        }, [_c('v-icon', [_vm._v(" mdi-trash-can ")])], 1)], 1)], 1) : _vm._e()];
      },
      proxy: true
    }])
  }, [_vm.message ? _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', [_c('v-text-field', {
    attrs: {
      "dense": "",
      "value": _vm.message.content.subject,
      "readonly": "",
      "outlined": "",
      "label": _vm.$t('components.template.dialog.preview.subject')
    }
  }), _vm.message.attachments.length ? _c('partner-message-detail-attachment-row', {
    staticClass: "mt-n5 mb-n3",
    attrs: {
      "value": _vm.message
    }
  }) : _vm._e(), _c('template-editor', {
    staticClass: "mb-n16",
    class: _vm.message.attachments.length ? '' : 'mt-n8',
    attrs: {
      "value": _vm.message.content.body,
      "readOnly": true,
      "outlined": true,
      "label": _vm.$t('components.template.dialog.preview.body')
    }
  })], 1)], 1) : _vm._e(), _vm.isMessageSideCardOpen ? _c('partner-message-side-card', {
    on: {
      "close": function close($event) {
        _vm.isMessageSideCardOpen = false;
      }
    },
    model: {
      value: _vm.message,
      callback: function callback($$v) {
        _vm.message = $$v;
      },
      expression: "message"
    }
  }) : _vm._e(), _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isDeleteDialogActive,
      "title": _vm.$t('components.partner.ActivityTimeLineItemDelete.title'),
      "rightText": _vm.$t('components.partner.ActivityTimeLineItemDelete.delete'),
      "isDeleteDialogLoading": _vm.isDeleteDialogLoading,
      "rightDisabled": _vm.isDeleteDialogLoading,
      "rightLoading": _vm.isDeleteDialogLoading
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "leftClick": function leftClick($event) {
        _vm.isDeleteDialogActive = false;
      },
      "rightClick": _vm.removeSourceRefFromMessageAndRemoveActivity
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.partner.ActivityTimeLineItemDelete.description")) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }